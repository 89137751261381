/*CSS file for CustomNavbar.jsx*/

@charset "utf-8";

.navbar-collapse {
    transition: 0.5s;
	width: 100%;
}

.navbar-toggler {
	position: absolute;
	width: auto;
	margin-top: 10px;
}

.navbar-toggler:focus {
	outline:0;
}

.navbar {
	padding-top: 1px;
	padding-bottom: 5px;
}

.navbar-brand {
	width: auto;
	font-weight:bold;
	text-align: left;
    font-size: 2rem;
	color:#0080ff;
	text-shadow: 2px 2px 12px #0080ff;
	margin: 0;
	padding-left: 10px;
}

.navbar-dark .navbar-nav .nav-link {
	color:#00a2f4;
	font-weight: bold;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus {
	color:#00a2f4;
}

.navbar-dark .navbar-brand:hover {
	color: white;
}

.menuItem {
	display: inline;
	font-size: 20px;
	text-align: center;
	margin-left: 2rem;
}

.menuItem:hover {
	background-color: #00a2f4;
	color: #343a40;
	text-decoration: none;
	width: auto;
	border-radius:10%;
}

.header {
	width:100%;
}

/*responsive styling*/
@media only screen {
	.menuItem:hover {
		border-radius: 0%;
	}

	.navbar {
		padding-left: 10px;
		padding-right: 10px;
	}

	.navbar-brand {
		padding-left: 5px;
	}
	
}

@media (max-width:991px) {
	.navbar {
		display: inline-block;
		padding-left: 10px;
		padding-right: 10px;
	}

	.navbar-brand {
		margin-left: 60px;
	}

	.menuItem {
		display: inline;
		margin-left: 0;
		text-align: center;

	}
}

@media (min-width:992px) {
	.menuItem:hover {
		border-radius:10%;
	}
}