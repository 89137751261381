/*CSS for Index.html*/

@charset "utf-8";

body {
	/*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;*/
	font-family: -apple-system, BlinkMacSystemFont, 'Oxygen', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	text-align:justify;
	background:#D4D4D4;
	margin-left:0;
	margin-bottom:0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
