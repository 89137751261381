/*CSS for App.js*/

a {
	color:#0080ff;
	font-size: 20px;
	text-decoration:none;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.centre {
	padding-top: 0%;
	text-align: center;
	padding-bottom: 0%;
}

.error {
	text-align: center;
}

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}