/*CSS for Home.jsx*/

@charset "utf-8";

.btn {
	margin: 6px;
}

.btn-outline-info {
	color: #0080ff;
	border-color: #0080ff;
}

.btn-outline-info:hover {
	background-color: #0080ff; 
	border-color: #0080ff;
}

h1 {
    font-size: 36px;
	text-align: center;
}

.heading {
	text-align: left;
}

h3, h4 {
	margin: 0;
}

li {
    font-size: 18px;
}

li>a {
	font-weight: bold;
}

.centre {
	padding-top: 0%;
	text-align: center;
	padding-bottom: 0%;
}

.home {
    padding: 4% 2% 5% 2%;
    background-color: #f8f8f8;
	width: 80%;
	margin-left:auto;
	margin-right:auto;
	z-index: 10; 
}

.home-text {
    font-size: 22px;
    text-align: left;
}

.profilePic {
	margin-left:auto;
	margin-right:auto;
	border-radius:50%;
	width: 40%;
}

@media only screen {
	
	.home {
		width: 95%;
		padding-left: 1.5%;
	}
	
	.home-text {
		text-align: left;
	}
	
	.heading {
		text-align: center;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2rem;
	}

	.profilePic {
		width: 30%;
	}
}

@media (min-width: 500px) {
	.home {
		width: 95%;
		padding-left: 1%;
	}
	
	.home-text {
		text-align: left;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2.5rem;
	}
}

@media (min-width: 576px) {
	.home {
		width: 80%;
		padding: 4% 2% 5% 2%;
	}
	
	.heading {
		text-align: left;
	}
	
}

@media (min-width: 768px) {
	li, ul>h3, ul h4 {
		margin-left: 0;
	}
}

@media (min-width: 1024px) {
	.home-text {
		text-align: left;
	}
}