/*CSS for About.jsx*/

@charset "utf-8";

h1.heading {
    font-size: 36px;
	text-align: center;
	padding-top: 5%;
}

.heading {
	text-align: left;
}

h3, h4 {
	margin: 0;
}

h5, li {
    font-size: 18px;
}

li:not(:last-child) {
	margin-bottom: 0.5em;
}

li:last-child {
	margin-bottom: 1.5em;
}

li>a {
	font-weight: bold;
}

section>section {
	padding-left: 40px;
}

.centre {
	padding-top: 0%;
	text-align: center;
	padding-bottom: 0%;
}

.about {
    padding: 5% 2% 5% 2%;
    background-color: #f8f8f8;
	width: 80%;
	margin-left:auto;
	margin-right:auto;
}

.about-text {
    font-size: 22px;
    text-align: left;
}

@media only screen {
	
	.about {
		width: 95%;
		padding-left: 1.5%;
	}
	
	.about-text {
		text-align: left;
	}

	h1.heading {
		padding-top: 18%;
	}

	.heading {
		text-align: center;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2rem;
	}
}

@media (min-width: 500px) {
	.about {
		width: 95%;
		padding-left: 1%;
	}
	
	.about-text {
		text-align: left;
	}

	h1.heading {
		padding-top: 10%;
	}

	li, ul>h3, ul h4 {
		margin-left: -2.5rem;
	}
}

@media (min-width: 576px) {
	.about {
		width: 80%;
		padding: 5% 2% 5% 2%;
	}
	
	.heading {
		text-align: left;
	}
	
}

@media (min-width: 768px) {
	li, ul>h3, ul h4 {
		margin-left: 0;
	}

	h1.heading {
		padding-top: 5%;
	}
}

@media (min-width: 1024px) {
	.about-text {
		text-align: left;
	}
}

@media (min-width: 1280px) {
	h1.heading {
		padding-top: 0;
	}
}