/*CSS file for Footer.jsx*/

@charset "utf-8";

footer {
	color: white;
}

body {
	font-size: 1.3rem;
}