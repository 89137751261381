/*CSS for Home.jsx*/

@charset "utf-8";

.error {
    padding: 5% 2% 5% 2%;
    background-color: #f8f8f8;
	width: 80%;
	margin-left:auto;
	margin-right:auto;
}


@media (min-width: 500px) {
	.error {
		width: 95%;
		padding-left: 1%;
	}
	
	.error-text {
		text-align: left;
	}

	h1.heading {
		padding-top: 10%;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2.5rem;
	}
}

@media (min-width: 576px) {
	.error {
		width: 80%;
		padding: 5% 2% 5% 2%;
	}
	
	.error-heading {
		text-align: left;
	}
	
}

@media (min-width: 768px) {
	li, ul>h3, ul h4 {
		margin-left: 0;
	}

	h1.heading {
		padding-top: 5%;
	}
}

@media (min-width: 1024px) {
	.error-text {
		text-align: left;
	}
}

@media (min-width: 1280px) {
	h1.heading {
		padding-top: 0;
	}
}