/*CSS for Programming.jsx*/

@charset "utf-8";

h1.heading {
    font-size: 36px;
	text-align: center;
	padding-top: 5%;
}

h2 {
	margin-bottom: 0;
}

h4 {
	margin-bottom: 9px;
}

li {
    font-size: 18px;
}

.centre {
	padding-top: 0%;
	text-align: center;
	padding-bottom: 0%;
}

.heading {
	text-align: left;
}

.programming {
    padding: 5% 2% 5% 2%;
    background-color: #f8f8f8;
	width: 90%;
	margin-left:auto;
	margin-right:auto;
}

.programming-text {
    font-size: 22px;
    text-align: left;
}

.programming-heading {
	text-align: left;
}

@media only screen {
	.programming {
		width: 95%;
		padding-left: 1.5%;
	}
	
	.programming-text {
		text-align: left;
	}

	h1.heading {
		padding-top: 18%;
	}

	.heading {
		text-align: center;
	}
	
	.programming-heading {
		text-align: center;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2rem;
	}
}

@media (min-width: 500px) {
	.programming {
		width: 95%;
		padding-left: 1%;
	}
	
	.programming-text {
		text-align: left;
	}

	h1.heading {
		padding-top: 10%;
	}
	
	li, ul>h3, ul h4 {
		margin-left: -2.5rem;
	}
}

@media (min-width: 576px) {
	.programming {
		width: 80%;
		padding: 5% 2% 5% 2%;
	}
	
	.programming-heading {
		text-align: left;
	}
	
}

@media (min-width: 768px) {
	li, ul>h3, ul h4 {
		margin-left: 0;
	}

	h1.heading {
		padding-top: 5%;
	}
}

@media (min-width: 1024px) {
	.programming-text {
		text-align: left;
	}
}

@media (min-width: 1280px) {
	h1.heading {
		padding-top: 0;
	}
}